/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OSBRepositoryBase,
    OSBRepositoryBaseFromJSON,
    OSBRepositoryBaseFromJSONTyped,
    OSBRepositoryBaseToJSON,
    RepositoryType,
    RepositoryTypeFromJSON,
    RepositoryTypeFromJSONTyped,
    RepositoryTypeToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * OSB Repository model
 * @export
 * @interface OSBRepositoryEntity
 */
export interface OSBRepositoryEntity {
    /**
     * 
     * @type {number}
     * @memberof OSBRepositoryEntity
     */
    id?: number;
    /**
     * Repository name.
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    name: string;
    /**
     * Summary describing the OSB Repository
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    summary?: string;
    /**
     * 
     * @type {RepositoryType}
     * @memberof OSBRepositoryEntity
     */
    repositoryType: RepositoryType;
    /**
     * List of Repository Content Types
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    contentTypes: string;
    /**
     * Auto sync of the resources
     * @type {boolean}
     * @memberof OSBRepositoryEntity
     */
    autoSync?: boolean;
    /**
     * URI of the repository
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    uri: string;
    /**
     * The default branch to show for this repository
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    defaultContext?: string;
    /**
     * 
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    thumbnail?: string;
    /**
     * OSBRepository keycloak user id, will be automatically be set to the logged in user
     * @type {string}
     * @memberof OSBRepositoryEntity
     */
    userId?: string;
    /**
     * Date/time the Workspace is created
     * @type {Date}
     * @memberof OSBRepositoryEntity
     */
    timestampCreated?: Date;
    /**
     * Date/time the Workspace is last updated
     * @type {Date}
     * @memberof OSBRepositoryEntity
     */
    timestampUpdated?: Date;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof OSBRepositoryEntity
     */
    tags?: Array<Tag>;
}

export function OSBRepositoryEntityFromJSON(json: any): OSBRepositoryEntity {
    return OSBRepositoryEntityFromJSONTyped(json, false);
}

export function OSBRepositoryEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OSBRepositoryEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'repositoryType': RepositoryTypeFromJSON(json['repository_type']),
        'contentTypes': json['content_types'],
        'autoSync': !exists(json, 'auto_sync') ? undefined : json['auto_sync'],
        'uri': json['uri'],
        'defaultContext': !exists(json, 'default_context') ? undefined : json['default_context'],
        'thumbnail': !exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'timestampCreated': !exists(json, 'timestamp_created') ? undefined : (new Date(json['timestamp_created'])),
        'timestampUpdated': !exists(json, 'timestamp_updated') ? undefined : (new Date(json['timestamp_updated'])),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function OSBRepositoryEntityToJSON(value?: OSBRepositoryEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'summary': value.summary,
        'repository_type': RepositoryTypeToJSON(value.repositoryType),
        'content_types': value.contentTypes,
        'auto_sync': value.autoSync,
        'uri': value.uri,
        'default_context': value.defaultContext,
        'thumbnail': value.thumbnail,
        'user_id': value.userId,
        'timestamp_created': value.timestampCreated === undefined ? undefined : (value.timestampCreated.toISOString()),
        'timestamp_updated': value.timestampUpdated === undefined ? undefined : (value.timestampUpdated.toISOString()),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
    };
}


