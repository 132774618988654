/* tslint:disable */
/* eslint-disable */
/**
 * accounts_api
 * Accounts rest api
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: cloudharness@metacell.us
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Group,
    GroupFromJSON,
    GroupToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface GetGroupRequest {
    groupname: string;
}

export interface GetGroupUsersRequest {
    groupname: string;
}

export interface UpdateGroupRequest {
    groupname: string;
    requestBody: { [key: string]: object; };
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     */
    async getGroupRaw(requestParameters: GetGroupRequest): Promise<runtime.ApiResponse<Group>> {
        if (requestParameters.groupname === null || requestParameters.groupname === undefined) {
            throw new runtime.RequiredError('groupname','Required parameter requestParameters.groupname was null or undefined when calling getGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/groups/{groupname}/`.replace(`{${"groupname"}}`, encodeURIComponent(String(requestParameters.groupname))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupFromJSON(jsonValue));
    }

    /**
     */
    async getGroup(requestParameters: GetGroupRequest): Promise<Group> {
        const response = await this.getGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getGroupUsersRaw(requestParameters: GetGroupUsersRequest): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.groupname === null || requestParameters.groupname === undefined) {
            throw new runtime.RequiredError('groupname','Required parameter requestParameters.groupname was null or undefined when calling getGroupUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/groups/{groupname}/users`.replace(`{${"groupname"}}`, encodeURIComponent(String(requestParameters.groupname))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async getGroupUsers(requestParameters: GetGroupUsersRequest): Promise<Array<User>> {
        const response = await this.getGroupUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.groupname === null || requestParameters.groupname === undefined) {
            throw new runtime.RequiredError('groupname','Required parameter requestParameters.groupname was null or undefined when calling updateGroup.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{groupname}/`.replace(`{${"groupname"}}`, encodeURIComponent(String(requestParameters.groupname))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async updateGroup(requestParameters: UpdateGroupRequest): Promise<User> {
        const response = await this.updateGroupRaw(requestParameters);
        return await response.value();
    }

}
