/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Resource status:
 *  * a - Available
 *  * e - Error, not available
 *  * p - Pending
 * @export
 * @enum {string}
 */
export enum ResourceStatus {
    A = 'a',
    E = 'e',
    P = 'p'
}

export function ResourceStatusFromJSON(json: any): ResourceStatus {
    return ResourceStatusFromJSONTyped(json, false);
}

export function ResourceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceStatus {
    return json as ResourceStatus;
}

export function ResourceStatusToJSON(value?: ResourceStatus | null): any {
    return value as any;
}

