/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * GIT hub specific attributes of the RepositoryResource
 * @export
 * @interface GITRepositoryResourceAllOf
 */
export interface GITRepositoryResourceAllOf {
    /**
     * The GIT ref
     * @type {string}
     * @memberof GITRepositoryResourceAllOf
     */
    ref?: string;
    /**
     * The GIT sha of the resource
     * @type {string}
     * @memberof GITRepositoryResourceAllOf
     */
    sha?: string;
}

export function GITRepositoryResourceAllOfFromJSON(json: any): GITRepositoryResourceAllOf {
    return GITRepositoryResourceAllOfFromJSONTyped(json, false);
}

export function GITRepositoryResourceAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GITRepositoryResourceAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ref': !exists(json, 'ref') ? undefined : json['ref'],
        'sha': !exists(json, 'sha') ? undefined : json['sha'],
    };
}

export function GITRepositoryResourceAllOfToJSON(value?: GITRepositoryResourceAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ref': value.ref,
        'sha': value.sha,
    };
}


