/* tslint:disable */
/* eslint-disable */
/**
 * accounts_api
 * Accounts rest api
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: cloudharness@metacell.us
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Group
     */
    quotas?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {any}
     * @memberof Group
     */
    description?: any | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Group
     */
    links?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Group
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    email?: string;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quotas': !exists(json, 'quotas') ? undefined : json['quotas'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'links': !exists(json, 'links') ? undefined : json['links'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quotas': value.quotas,
        'name': value.name,
        'description': value.description,
        'links': value.links,
        'keywords': value.keywords,
        'image': value.image,
        'email': value.email,
    };
}


