/* tslint:disable */
/* eslint-disable */
/**
 * accounts_api
 * Accounts rest api
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: cloudharness@metacell.us
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

/**
 * 
 */
export class InfrastructureApi extends runtime.BaseAPI {

    /**
     * Test if application is healthy
     */
    async liveRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/live`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Test if application is healthy
     */
    async live(): Promise<string> {
        const response = await this.liveRaw();
        return await response.value();
    }

    /**
     * Test if application is ready to take requests
     */
    async readyRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Test if application is ready to take requests
     */
    async ready(): Promise<string> {
        const response = await this.readyRaw();
        return await response.value();
    }

}
