/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    InlineResponse2002,
    InlineResponse2002FromJSON,
    InlineResponse2002ToJSON,
    InlineResponse2003,
    InlineResponse2003FromJSON,
    InlineResponse2003ToJSON,
    OSBRepository,
    OSBRepositoryFromJSON,
    OSBRepositoryToJSON,
    RepositoryInfo,
    RepositoryInfoFromJSON,
    RepositoryInfoToJSON,
    RepositoryType,
    RepositoryTypeFromJSON,
    RepositoryTypeToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    VolumeStorage,
    VolumeStorageFromJSON,
    VolumeStorageToJSON,
    Workspace,
    WorkspaceFromJSON,
    WorkspaceToJSON,
    WorkspaceResource,
    WorkspaceResourceFromJSON,
    WorkspaceResourceToJSON,
} from '../models';

export interface DelimageRequest {
    id: number;
    imageId: number;
}

export interface GetDescriptionRequest {
    uri: string;
    repositoryType: RepositoryType;
    context: string;
}

export interface GetInfoRequest {
    uri: string;
    repositoryType: RepositoryType;
}

export interface OsbrepositoryGetRequest {
    page?: number;
    perPage?: number;
    q?: string | null;
    tags?: string | null;
    types?: string | null;
    userId?: string | null;
}

export interface OsbrepositoryIdDeleteRequest {
    id: number;
    context?: string;
}

export interface OsbrepositoryIdGetRequest {
    id: number;
    context?: string;
}

export interface OsbrepositoryIdPutRequest {
    id: number;
    oSBRepository: OSBRepository;
    context?: string;
}

export interface OsbrepositoryPostRequest {
    oSBRepository: OSBRepository;
}

export interface TagGetRequest {
    page?: number;
    perPage?: number;
    q?: string;
}

export interface TagIdDeleteRequest {
    id: number;
}

export interface TagIdGetRequest {
    id: number;
}

export interface TagIdPutRequest {
    id: number;
    tag: Tag;
}

export interface TagPostRequest {
    tag: Tag;
}

export interface VolumestorageGetRequest {
    page?: number;
    perPage?: number;
    q?: string;
}

export interface VolumestorageIdDeleteRequest {
    id: number;
}

export interface VolumestorageIdGetRequest {
    id: number;
}

export interface VolumestorageIdPutRequest {
    id: number;
    volumeStorage: VolumeStorage;
}

export interface VolumestoragePostRequest {
    volumeStorage: VolumeStorage;
}

export interface WorkspaceGetRequest {
    page?: number;
    perPage?: number;
    q?: string;
    tags?: string | null;
}

export interface WorkspaceIdDeleteRequest {
    id: number;
}

export interface WorkspaceIdGetRequest {
    id: number;
}

export interface WorkspaceIdPutRequest {
    id: number;
    workspace: Workspace;
}

export interface WorkspacePostRequest {
    workspace: Workspace;
}

export interface WorkspaceresourceIdDeleteRequest {
    id: number;
}

export interface WorkspaceresourceIdGetRequest {
    id: number;
}

export interface WorkspaceresourceIdPutRequest {
    id: number;
    workspaceResource: WorkspaceResource;
}

export interface WorkspaceresourcePostRequest {
    workspaceResource: WorkspaceResource;
}

export interface WorkspacesControllersOsbrepositoryControllerSetthumbnailRequest {
    id: number;
    thumbNail?: Blob;
}

export interface WorkspacesControllersWorkspaceControllerAddimageRequest {
    id: number;
    image?: Blob;
}

export interface WorkspacesControllersWorkspaceControllerImportResourcesRequest {
    id: number;
    inlineObject?: InlineObject;
}

export interface WorkspacesControllersWorkspaceControllerSetthumbnailRequest {
    id: number;
    thumbNail?: Blob;
}

export interface WorkspacesControllersWorkspaceControllerWorkspaceCloneRequest {
    id: number;
}

export interface WorkspacesControllersWorkspaceResourceControllerOpenRequest {
    id: number;
}

/**
 * 
 */
export class RestApi extends runtime.BaseAPI {

    /**
     * Delete a Workspace Image from the workspace.
     */
    async delimageRaw(requestParameters: DelimageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling delimage.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling delimage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}/gallery/{image_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a Workspace Image from the workspace.
     */
    async delimage(requestParameters: DelimageRequest): Promise<void> {
        await this.delimageRaw(requestParameters);
    }

    /**
     * Used to retrieve description for a repository.
     */
    async getDescriptionRaw(requestParameters: GetDescriptionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.uri === null || requestParameters.uri === undefined) {
            throw new runtime.RequiredError('uri','Required parameter requestParameters.uri was null or undefined when calling getDescription.');
        }

        if (requestParameters.repositoryType === null || requestParameters.repositoryType === undefined) {
            throw new runtime.RequiredError('repositoryType','Required parameter requestParameters.repositoryType was null or undefined when calling getDescription.');
        }

        if (requestParameters.context === null || requestParameters.context === undefined) {
            throw new runtime.RequiredError('context','Required parameter requestParameters.context was null or undefined when calling getDescription.');
        }

        const queryParameters: any = {};

        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }

        if (requestParameters.repositoryType !== undefined) {
            queryParameters['repository_type'] = requestParameters.repositoryType;
        }

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/osbrepository/description`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Used to retrieve description for a repository.
     */
    async getDescription(requestParameters: GetDescriptionRequest): Promise<string> {
        const response = await this.getDescriptionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to retrieve a list of contexts of a repository.
     */
    async getInfoRaw(requestParameters: GetInfoRequest): Promise<runtime.ApiResponse<RepositoryInfo>> {
        if (requestParameters.uri === null || requestParameters.uri === undefined) {
            throw new runtime.RequiredError('uri','Required parameter requestParameters.uri was null or undefined when calling getInfo.');
        }

        if (requestParameters.repositoryType === null || requestParameters.repositoryType === undefined) {
            throw new runtime.RequiredError('repositoryType','Required parameter requestParameters.repositoryType was null or undefined when calling getInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.uri !== undefined) {
            queryParameters['uri'] = requestParameters.uri;
        }

        if (requestParameters.repositoryType !== undefined) {
            queryParameters['repository_type'] = requestParameters.repositoryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/osbrepository/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepositoryInfoFromJSON(jsonValue));
    }

    /**
     * Used to retrieve a list of contexts of a repository.
     */
    async getInfo(requestParameters: GetInfoRequest): Promise<RepositoryInfo> {
        const response = await this.getInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to list all available repositories.
     */
    async osbrepositoryGetRaw(requestParameters: OsbrepositoryGetRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.tags !== undefined) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.types !== undefined) {
            queryParameters['types'] = requestParameters.types;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['user_id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/osbrepository`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Used to list all available repositories.
     */
    async osbrepositoryGet(requestParameters: OsbrepositoryGetRequest): Promise<InlineResponse2001> {
        const response = await this.osbrepositoryGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a OSBRepository.
     */
    async osbrepositoryIdDeleteRaw(requestParameters: OsbrepositoryIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling osbrepositoryIdDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/osbrepository/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a OSBRepository.
     */
    async osbrepositoryIdDelete(requestParameters: OsbrepositoryIdDeleteRequest): Promise<void> {
        await this.osbrepositoryIdDeleteRaw(requestParameters);
    }

    /**
     * Used to retrieve a repository.
     */
    async osbrepositoryIdGetRaw(requestParameters: OsbrepositoryIdGetRequest): Promise<runtime.ApiResponse<OSBRepository>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling osbrepositoryIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/osbrepository/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OSBRepositoryFromJSON(jsonValue));
    }

    /**
     * Used to retrieve a repository.
     */
    async osbrepositoryIdGet(requestParameters: OsbrepositoryIdGetRequest): Promise<OSBRepository> {
        const response = await this.osbrepositoryIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a OSB repository.
     */
    async osbrepositoryIdPutRaw(requestParameters: OsbrepositoryIdPutRequest): Promise<runtime.ApiResponse<OSBRepository>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling osbrepositoryIdPut.');
        }

        if (requestParameters.oSBRepository === null || requestParameters.oSBRepository === undefined) {
            throw new runtime.RequiredError('oSBRepository','Required parameter requestParameters.oSBRepository was null or undefined when calling osbrepositoryIdPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.context !== undefined) {
            queryParameters['context'] = requestParameters.context;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/osbrepository/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OSBRepositoryToJSON(requestParameters.oSBRepository),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OSBRepositoryFromJSON(jsonValue));
    }

    /**
     * Update a OSB repository.
     */
    async osbrepositoryIdPut(requestParameters: OsbrepositoryIdPutRequest): Promise<OSBRepository> {
        const response = await this.osbrepositoryIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to save a OSB Repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async osbrepositoryPostRaw(requestParameters: OsbrepositoryPostRequest): Promise<runtime.ApiResponse<OSBRepository>> {
        if (requestParameters.oSBRepository === null || requestParameters.oSBRepository === undefined) {
            throw new runtime.RequiredError('oSBRepository','Required parameter requestParameters.oSBRepository was null or undefined when calling osbrepositoryPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/osbrepository`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OSBRepositoryToJSON(requestParameters.oSBRepository),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OSBRepositoryFromJSON(jsonValue));
    }

    /**
     * Used to save a OSB Repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async osbrepositoryPost(requestParameters: OsbrepositoryPostRequest): Promise<OSBRepository> {
        const response = await this.osbrepositoryPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to list all available tags.
     */
    async tagGetRaw(requestParameters: TagGetRequest): Promise<runtime.ApiResponse<InlineResponse2003>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2003FromJSON(jsonValue));
    }

    /**
     * Used to list all available tags.
     */
    async tagGet(requestParameters: TagGetRequest): Promise<InlineResponse2003> {
        const response = await this.tagGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an tag from the repository.
     */
    async tagIdDeleteRaw(requestParameters: TagIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tagIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tag/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an tag from the repository.
     */
    async tagIdDelete(requestParameters: TagIdDeleteRequest): Promise<void> {
        await this.tagIdDeleteRaw(requestParameters);
    }

    /**
     * Used to retrieve an tag from the repository.
     */
    async tagIdGetRaw(requestParameters: TagIdGetRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tagIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tag/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Used to retrieve an tag from the repository.
     */
    async tagIdGet(requestParameters: TagIdGetRequest): Promise<Tag> {
        const response = await this.tagIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an tag in the repository.
     */
    async tagIdPutRaw(requestParameters: TagIdPutRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling tagIdPut.');
        }

        if (requestParameters.tag === null || requestParameters.tag === undefined) {
            throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling tagIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tag/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Update an tag in the repository.
     */
    async tagIdPut(requestParameters: TagIdPutRequest): Promise<Tag> {
        const response = await this.tagIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to save a Tag to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async tagPostRaw(requestParameters: TagPostRequest): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters.tag === null || requestParameters.tag === undefined) {
            throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling tagPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     * Used to save a Tag to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async tagPost(requestParameters: TagPostRequest): Promise<Tag> {
        const response = await this.tagPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to list all available volumestorages.
     */
    async volumestorageGetRaw(requestParameters: VolumestorageGetRequest): Promise<runtime.ApiResponse<InlineResponse2002>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/volumestorage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2002FromJSON(jsonValue));
    }

    /**
     * Used to list all available volumestorages.
     */
    async volumestorageGet(requestParameters: VolumestorageGetRequest): Promise<InlineResponse2002> {
        const response = await this.volumestorageGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an volumestorage from the repository.
     */
    async volumestorageIdDeleteRaw(requestParameters: VolumestorageIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling volumestorageIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/volumestorage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an volumestorage from the repository.
     */
    async volumestorageIdDelete(requestParameters: VolumestorageIdDeleteRequest): Promise<void> {
        await this.volumestorageIdDeleteRaw(requestParameters);
    }

    /**
     * Used to retrieve an volumestorage from the repository.
     */
    async volumestorageIdGetRaw(requestParameters: VolumestorageIdGetRequest): Promise<runtime.ApiResponse<VolumeStorage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling volumestorageIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/volumestorage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VolumeStorageFromJSON(jsonValue));
    }

    /**
     * Used to retrieve an volumestorage from the repository.
     */
    async volumestorageIdGet(requestParameters: VolumestorageIdGetRequest): Promise<VolumeStorage> {
        const response = await this.volumestorageIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an volumestorage in the repository.
     */
    async volumestorageIdPutRaw(requestParameters: VolumestorageIdPutRequest): Promise<runtime.ApiResponse<VolumeStorage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling volumestorageIdPut.');
        }

        if (requestParameters.volumeStorage === null || requestParameters.volumeStorage === undefined) {
            throw new runtime.RequiredError('volumeStorage','Required parameter requestParameters.volumeStorage was null or undefined when calling volumestorageIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/volumestorage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VolumeStorageToJSON(requestParameters.volumeStorage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VolumeStorageFromJSON(jsonValue));
    }

    /**
     * Update an volumestorage in the repository.
     */
    async volumestorageIdPut(requestParameters: VolumestorageIdPutRequest): Promise<VolumeStorage> {
        const response = await this.volumestorageIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to save a VolumeStorage to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async volumestoragePostRaw(requestParameters: VolumestoragePostRequest): Promise<runtime.ApiResponse<VolumeStorage>> {
        if (requestParameters.volumeStorage === null || requestParameters.volumeStorage === undefined) {
            throw new runtime.RequiredError('volumeStorage','Required parameter requestParameters.volumeStorage was null or undefined when calling volumestoragePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/volumestorage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VolumeStorageToJSON(requestParameters.volumeStorage),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VolumeStorageFromJSON(jsonValue));
    }

    /**
     * Used to save a VolumeStorage to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async volumestoragePost(requestParameters: VolumestoragePostRequest): Promise<VolumeStorage> {
        const response = await this.volumestoragePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to list all available workspaces.
     */
    async workspaceGetRaw(requestParameters: WorkspaceGetRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['per_page'] = requestParameters.perPage;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.tags !== undefined) {
            queryParameters['tags'] = requestParameters.tags;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Used to list all available workspaces.
     */
    async workspaceGet(requestParameters: WorkspaceGetRequest): Promise<InlineResponse200> {
        const response = await this.workspaceGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a workspace from the repository.
     */
    async workspaceIdDeleteRaw(requestParameters: WorkspaceIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a workspace from the repository.
     */
    async workspaceIdDelete(requestParameters: WorkspaceIdDeleteRequest): Promise<void> {
        await this.workspaceIdDeleteRaw(requestParameters);
    }

    /**
     * Used to retrieve a workspace from the repository.
     */
    async workspaceIdGetRaw(requestParameters: WorkspaceIdGetRequest): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Used to retrieve a workspace from the repository.
     */
    async workspaceIdGet(requestParameters: WorkspaceIdGetRequest): Promise<Workspace> {
        const response = await this.workspaceIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a workspace in the repository.
     */
    async workspaceIdPutRaw(requestParameters: WorkspaceIdPutRequest): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceIdPut.');
        }

        if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
            throw new runtime.RequiredError('workspace','Required parameter requestParameters.workspace was null or undefined when calling workspaceIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceToJSON(requestParameters.workspace),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Update a workspace in the repository.
     */
    async workspaceIdPut(requestParameters: WorkspaceIdPutRequest): Promise<Workspace> {
        const response = await this.workspaceIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to save a Workspace to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async workspacePostRaw(requestParameters: WorkspacePostRequest): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.workspace === null || requestParameters.workspace === undefined) {
            throw new runtime.RequiredError('workspace','Required parameter requestParameters.workspace was null or undefined when calling workspacePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceToJSON(requestParameters.workspace),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Used to save a Workspace to the repository. The user_id (keycloak user id) will be automatically filled with the current user
     */
    async workspacePost(requestParameters: WorkspacePostRequest): Promise<Workspace> {
        const response = await this.workspacePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a WorkspaceResource.
     */
    async workspaceresourceIdDeleteRaw(requestParameters: WorkspaceresourceIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceresourceIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaceresource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a WorkspaceResource.
     */
    async workspaceresourceIdDelete(requestParameters: WorkspaceresourceIdDeleteRequest): Promise<void> {
        await this.workspaceresourceIdDeleteRaw(requestParameters);
    }

    /**
     * Used to retrieve a WorkspaceResource.
     */
    async workspaceresourceIdGetRaw(requestParameters: WorkspaceresourceIdGetRequest): Promise<runtime.ApiResponse<WorkspaceResource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceresourceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaceresource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceFromJSON(jsonValue));
    }

    /**
     * Used to retrieve a WorkspaceResource.
     */
    async workspaceresourceIdGet(requestParameters: WorkspaceresourceIdGetRequest): Promise<WorkspaceResource> {
        const response = await this.workspaceresourceIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the WorkspaceResource.
     */
    async workspaceresourceIdPutRaw(requestParameters: WorkspaceresourceIdPutRequest): Promise<runtime.ApiResponse<WorkspaceResource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspaceresourceIdPut.');
        }

        if (requestParameters.workspaceResource === null || requestParameters.workspaceResource === undefined) {
            throw new runtime.RequiredError('workspaceResource','Required parameter requestParameters.workspaceResource was null or undefined when calling workspaceresourceIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaceresource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceResourceToJSON(requestParameters.workspaceResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceFromJSON(jsonValue));
    }

    /**
     * Update the WorkspaceResource.
     */
    async workspaceresourceIdPut(requestParameters: WorkspaceresourceIdPutRequest): Promise<WorkspaceResource> {
        const response = await this.workspaceresourceIdPutRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to save a WorkspaceResource to the repository.
     */
    async workspaceresourcePostRaw(requestParameters: WorkspaceresourcePostRequest): Promise<runtime.ApiResponse<WorkspaceResource>> {
        if (requestParameters.workspaceResource === null || requestParameters.workspaceResource === undefined) {
            throw new runtime.RequiredError('workspaceResource','Required parameter requestParameters.workspaceResource was null or undefined when calling workspaceresourcePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaceresource`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkspaceResourceToJSON(requestParameters.workspaceResource),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceResourceFromJSON(jsonValue));
    }

    /**
     * Used to save a WorkspaceResource to the repository.
     */
    async workspaceresourcePost(requestParameters: WorkspaceresourcePostRequest): Promise<WorkspaceResource> {
        const response = await this.workspaceresourcePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sets the thumbnail of the workspace.
     */
    async workspacesControllersOsbrepositoryControllerSetthumbnailRaw(requestParameters: WorkspacesControllersOsbrepositoryControllerSetthumbnailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersOsbrepositoryControllerSetthumbnail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.thumbNail !== undefined) {
            formParams.append('thumbNail', requestParameters.thumbNail as any);
        }

        const response = await this.request({
            path: `/osbrepository/{id}/thumbnail/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the thumbnail of the workspace.
     */
    async workspacesControllersOsbrepositoryControllerSetthumbnail(requestParameters: WorkspacesControllersOsbrepositoryControllerSetthumbnailRequest): Promise<void> {
        await this.workspacesControllersOsbrepositoryControllerSetthumbnailRaw(requestParameters);
    }

    /**
     * Adds and image to the workspace.
     */
    async workspacesControllersWorkspaceControllerAddimageRaw(requestParameters: WorkspacesControllersWorkspaceControllerAddimageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersWorkspaceControllerAddimage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/workspace/{id}/gallery`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds and image to the workspace.
     */
    async workspacesControllersWorkspaceControllerAddimage(requestParameters: WorkspacesControllersWorkspaceControllerAddimageRequest): Promise<void> {
        await this.workspacesControllersWorkspaceControllerAddimageRaw(requestParameters);
    }

    /**
     * Imports the ResourceOrigins into the Workspace and creates/updates the workspace resources
     */
    async workspacesControllersWorkspaceControllerImportResourcesRaw(requestParameters: WorkspacesControllersWorkspaceControllerImportResourcesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersWorkspaceControllerImportResources.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}/import`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Imports the ResourceOrigins into the Workspace and creates/updates the workspace resources
     */
    async workspacesControllersWorkspaceControllerImportResources(requestParameters: WorkspacesControllersWorkspaceControllerImportResourcesRequest): Promise<void> {
        await this.workspacesControllersWorkspaceControllerImportResourcesRaw(requestParameters);
    }

    /**
     * Sets the thumbnail of the workspace.
     */
    async workspacesControllersWorkspaceControllerSetthumbnailRaw(requestParameters: WorkspacesControllersWorkspaceControllerSetthumbnailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersWorkspaceControllerSetthumbnail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.thumbNail !== undefined) {
            formParams.append('thumbNail', requestParameters.thumbNail as any);
        }

        const response = await this.request({
            path: `/workspace/{id}/thumbnail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets the thumbnail of the workspace.
     */
    async workspacesControllersWorkspaceControllerSetthumbnail(requestParameters: WorkspacesControllersWorkspaceControllerSetthumbnailRequest): Promise<void> {
        await this.workspacesControllersWorkspaceControllerSetthumbnailRaw(requestParameters);
    }

    /**
     * Clones a workspace
     */
    async workspacesControllersWorkspaceControllerWorkspaceCloneRaw(requestParameters: WorkspacesControllersWorkspaceControllerWorkspaceCloneRequest): Promise<runtime.ApiResponse<Workspace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersWorkspaceControllerWorkspaceClone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspace/{id}/clone`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkspaceFromJSON(jsonValue));
    }

    /**
     * Clones a workspace
     */
    async workspacesControllersWorkspaceControllerWorkspaceClone(requestParameters: WorkspacesControllersWorkspaceControllerWorkspaceCloneRequest): Promise<Workspace> {
        const response = await this.workspacesControllersWorkspaceControllerWorkspaceCloneRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to register a WorkspaceResource open action. The WorkspaceResource timestamp last open will be updated
     */
    async workspacesControllersWorkspaceResourceControllerOpenRaw(requestParameters: WorkspacesControllersWorkspaceResourceControllerOpenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workspacesControllersWorkspaceResourceControllerOpen.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workspaceresource/{id}/open`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to register a WorkspaceResource open action. The WorkspaceResource timestamp last open will be updated
     */
    async workspacesControllersWorkspaceResourceControllerOpen(requestParameters: WorkspacesControllersWorkspaceResourceControllerOpenRequest): Promise<void> {
        await this.workspacesControllersWorkspaceResourceControllerOpenRaw(requestParameters);
    }

}
