/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Workspace images of a workspace
 * @export
 * @interface WorkspaceImage
 */
export interface WorkspaceImage {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceImage
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceImage
     */
    image: string;
}

export function WorkspaceImageFromJSON(json: any): WorkspaceImage {
    return WorkspaceImageFromJSONTyped(json, false);
}

export function WorkspaceImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': json['image'],
    };
}

export function WorkspaceImageToJSON(value?: WorkspaceImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'image': value.image,
    };
}


