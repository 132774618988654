/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RepositoryContentType,
    RepositoryContentTypeFromJSON,
    RepositoryContentTypeFromJSONTyped,
    RepositoryContentTypeToJSON,
    RepositoryResourceNode,
    RepositoryResourceNodeFromJSON,
    RepositoryResourceNodeFromJSONTyped,
    RepositoryResourceNodeToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * extra attributes
 * @export
 * @interface OSBRepositoryAllOf
 */
export interface OSBRepositoryAllOf {
    /**
     * 
     * @type {RepositoryResourceNode}
     * @memberof OSBRepositoryAllOf
     */
    contextResources?: RepositoryResourceNode;
    /**
     * 
     * @type {Array<string>}
     * @memberof OSBRepositoryAllOf
     */
    contexts?: Array<string>;
    /**
     * 
     * @type {User}
     * @memberof OSBRepositoryAllOf
     */
    user?: User;
    /**
     * 
     * @type {Array<RepositoryContentType>}
     * @memberof OSBRepositoryAllOf
     */
    contentTypesList?: Array<RepositoryContentType>;
    /**
     * Repository description
     * @type {string}
     * @memberof OSBRepositoryAllOf
     */
    description?: string;
    /**
     * Date/time the OSBReposity is last modified
     * @type {Date}
     * @memberof OSBRepositoryAllOf
     */
    timestampModified?: Date;
}

export function OSBRepositoryAllOfFromJSON(json: any): OSBRepositoryAllOf {
    return OSBRepositoryAllOfFromJSONTyped(json, false);
}

export function OSBRepositoryAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): OSBRepositoryAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contextResources': !exists(json, 'context_resources') ? undefined : RepositoryResourceNodeFromJSON(json['context_resources']),
        'contexts': !exists(json, 'contexts') ? undefined : json['contexts'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'contentTypesList': !exists(json, 'content_types_list') ? undefined : ((json['content_types_list'] as Array<any>).map(RepositoryContentTypeFromJSON)),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'timestampModified': !exists(json, 'timestamp_modified') ? undefined : (new Date(json['timestamp_modified'])),
    };
}

export function OSBRepositoryAllOfToJSON(value?: OSBRepositoryAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context_resources': RepositoryResourceNodeToJSON(value.contextResources),
        'contexts': value.contexts,
        'user': UserToJSON(value.user),
        'content_types_list': value.contentTypesList === undefined ? undefined : ((value.contentTypesList as Array<any>).map(RepositoryContentTypeToJSON)),
        'description': value.description,
        'timestamp_modified': value.timestampModified === undefined ? undefined : (value.timestampModified.toISOString()),
    };
}


