/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    WorkspaceResource,
    WorkspaceResourceFromJSON,
    WorkspaceResourceFromJSONTyped,
    WorkspaceResourceToJSON,
} from './';

/**
 * extra attributes
 * @export
 * @interface WorkspaceAllOf
 */
export interface WorkspaceAllOf {
    /**
     * Resources of the workspace
     * @type {Array<WorkspaceResource>}
     * @memberof WorkspaceAllOf
     */
    resources?: Array<WorkspaceResource>;
    /**
     * 
     * @type {User}
     * @memberof WorkspaceAllOf
     */
    user?: User;
}

export function WorkspaceAllOfFromJSON(json: any): WorkspaceAllOf {
    return WorkspaceAllOfFromJSONTyped(json, false);
}

export function WorkspaceAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': !exists(json, 'resources') ? undefined : ((json['resources'] as Array<any>).map(WorkspaceResourceFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function WorkspaceAllOfToJSON(value?: WorkspaceAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': value.resources === undefined ? undefined : ((value.resources as Array<any>).map(WorkspaceResourceToJSON)),
        'user': UserToJSON(value.user),
    };
}


