/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepositoryInfo
 */
export interface RepositoryInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryInfo
     */
    contexts?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    summary?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepositoryInfo
     */
    tags?: Array<string>;
}

export function RepositoryInfoFromJSON(json: any): RepositoryInfo {
    return RepositoryInfoFromJSONTyped(json, false);
}

export function RepositoryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepositoryInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contexts': !exists(json, 'contexts') ? undefined : json['contexts'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function RepositoryInfoToJSON(value?: RepositoryInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contexts': value.contexts,
        'name': value.name,
        'summary': value.summary,
        'tags': value.tags,
    };
}


