/* tslint:disable */
/* eslint-disable */
/**
 * OSB Workspaces manager API
 * Opensource Brain Platform - Reference Workspaces manager API
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceResourceEntityAllOf
 */
export interface WorkspaceResourceEntityAllOf {
    /**
     * Origin data JSON formatted of the WorkspaceResource
     * @type {string}
     * @memberof WorkspaceResourceEntityAllOf
     */
    origin?: string;
    /**
     * workspace_id
     * @type {number}
     * @memberof WorkspaceResourceEntityAllOf
     */
    workspaceId?: number;
    /**
     * WorkspaceResource path where the resource will stored in the pvc.
     * @type {string}
     * @memberof WorkspaceResourceEntityAllOf
     */
    folder?: string;
}

export function WorkspaceResourceEntityAllOfFromJSON(json: any): WorkspaceResourceEntityAllOf {
    return WorkspaceResourceEntityAllOfFromJSONTyped(json, false);
}

export function WorkspaceResourceEntityAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceResourceEntityAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'workspaceId': !exists(json, 'workspace_id') ? undefined : json['workspace_id'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
    };
}

export function WorkspaceResourceEntityAllOfToJSON(value?: WorkspaceResourceEntityAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'origin': value.origin,
        'workspace_id': value.workspaceId,
        'folder': value.folder,
    };
}


